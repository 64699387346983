import React from 'react';
import dude from '../imgs/ventilation-page-img.jpg';
import '../css/services.css';

function DuctCleaning() {
    return (
        <div className='heating-page service'>
            <div className='wrapper'>
                <div className='service-cont'>
                    <div>
                        <img alt='furnace' className='service-img' src={dude}/>
                    </div>
                    <div>
                        <h1 className='service-title'>Ventilation &amp; Duct Cleaning</h1>
                        <p className='service-text'>It’s a known fact that duct-work inside a structure will accumulate a host of pollutants over time. Ducts carry pet hair, dander, debris, even fungus and mould which become airborne each time your heating or air conditioning system turns on. If contaminants are left to build up in your air ducts, it will reduce the airflow in your home and your heating and cooling units will not run efficiently. When your furnace or air conditioner works harder, your monthly energy bills will increase and your systems may experience more performance issues and breakdowns.</p>
                        <p className='service-text'>TLC Heating &amp; Cooling can help you avoid all of these issues with our professional duct cleaning services. We have been serving residents and businesses in Calgary with quality work for over a decade.</p>
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default DuctCleaning;
