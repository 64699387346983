import React from 'react';
import acUnit from '../imgs/ac-unit-1.jpg';
import '../css/services.css';

function Cooling() {
    return (
        <div className='cooling-page service'>
            <div className='wrapper'>
                <div className='service-cont'>
                    <div>
                        <img alt='Air Conditioners' className='service-img' src={acUnit}/>
                    </div>
                    <div>
                        <h1 className='service-title'>Professional Air Conditioning &amp; Refrigeration Services</h1>
                        <p className='service-text'>As climate professionals, we know how important the luxury of cool temperatures can be during the hot summer months, but if your AC unit is not running efficiently, these luxuries can come at a high cost. You can save money by maintaining your air conditioner with routine service checks. The last thing you want is for your cooling unit to stop working during the hottest time of the year! With regular maintenance your system should operate efficiently and less energy will be required to cool your home.</p>
                        <p className='service-text'>TLC Heating &amp; Cooling works with quality name brands from reputable manufacturers. With 24-hour service, fully equipped trucks and qualified technicians at your service, there’s no need for sweltering indoor temperatures this summer.</p>
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default Cooling;
