import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Nav from './comps/nav';
import Home from './pages/home';
import Footer from './comps/footer';
import Heating from './pages/heating';
import Cooling from './pages/cooling';
import Testimonials from './pages/testimonials';
import installsAndRepairs from './pages/installs-and-repairs';
import DuctCleaning from './pages/vents-and-duct-cleaning';


function App() {
  return (
    <Router>
      <div className='app'>
        <Nav />
        <Switch>
           <Route path='/' exact component={Home}/>
           <Route path='/services/heating' component={Heating}/>
           <Route path='/services/cooling' component={Cooling}/>
           <Route path='/services/installs-and-repairs' component={installsAndRepairs}/>
           <Route path='/services/duct-cleaning' component={DuctCleaning}/>
           <Route path='/testimonials' component={Testimonials}/>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
