import React from 'react';
import '../css/footer.css';

function Footer() {
  return (
    <div className='footer'>
        <p className='copyright'>&copy; TLC Heating &amp; Cooling {(new Date().getFullYear())}</p>
        <p className='shameless-plug'>A Wisser Web Design Production</p>
    </div>
  );
}

export default Footer;
