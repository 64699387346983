import React from 'react';
import Testimonial from '../comps/testimonial';




const Testimonials = () => {

  return (
    <div className='wrapper'>
        <h1 style={{color: 'white', padding: '10px 0 0 0'}}>Testimonials</h1>
        <Testimonial name='Deanna Nickoriuk' rating={5} reviewText='Called TLC Heating this morning after our furnace starting having problems. Trevor was great! He made arrangements to come over later the same day and was there before expected.  He was knowledgeable and professional and quickly diagnosed and solved the problem, without any hidden surprises or extra costs. He just did what needed done and gave recommendations to keep in mind to keep things running smoothly. I was very happy with the service and price, and would highly recommend to anyone!'/>
        <Testimonial name='Kurtis Bain' rating={5} reviewText='I use TLC Heating and Cooling for all my HVAC needs as an owner of a property management firm. Trevor and his team are prompt, reliable and affordable. I have used many companies over my career and TLC is by far the best.'/>
        <Testimonial name='Jennifer Svoboda' rating={5} reviewText='"Ive got a guy for that," is what I can say now if anyone needs help with their furnace or air conditioning! Trevor and Quinn arrived early to install our new air conditioning unit, worked diligently the entire time, and were done in a matter of hours. Trevor will give you honest and fair service, without "up-selling" you on the more expensive option if you dont need it. The air conditioning is a godsend, and we are so impressed with the price and hard work from TLC. Will recommend them any time.'/>
        <Testimonial name='Bartletts Okotoks' rating={5} reviewText='Good morning! Turns out one of our air conditioning units needed to be replaced. Trevor had it installed in less than a week! Very happy with his service!'/> 
    </div>
  );
}

export default Testimonials;
