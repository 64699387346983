import React from 'react';
import Dude from '../imgs/dude-installing.jpg';
import '../css/services.css';

function installsAndRepairs() {
    return (
        <div className='heating-page service'>
            <div className='wrapper'>
                <div className='service-cont'>
                    <div>
                        <img alt='Technician inspecting AC unit' className='service-img installs-img' src={Dude}/>
                    </div>
                    <div>
                        <h1 className='service-title'>Professional HVAC Installations &amp; Repairs</h1>
                        <p className='service-text'>When the time comes to have your outdated or under-performing equipment inspected, repaired and/or replaced, the number of available contractors can seem overwhelming. Not all of them deliver what they promise, and as a result, it can be challenging to find one you feel you can trust with such an outstanding job. By working with TLC Heating &amp; Cooling, however, you will see that we go to extra lengths to ensure that you are fully satisfied with your service. Our goal is to make your home feel comfortable, inviting, and safe with a system that delivers the exact output and performance you need.</p>
                        <p className='service-text'>Our expert technicians are trained to immediately address any potential issues with your equipment during installation, including potential risks to your safety or the performance of the system itself. TLC Heating &amp; Cooling will take care of situations straight away. If a part is damaged, a connection is broken, or your unit isn’t performing as expected, we’re going to take care of it without any hassle. We take the safety, comfort, and well-being of our customers very seriously, and the philosophy behind our business is that these factors always come first.</p>
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default installsAndRepairs;
