import React from 'react';
import furnace from '../imgs/furnace.jpg';
import '../css/services.css';

function Heating() {
    return (
        <div className='heating-page service'>
            <div className='wrapper'>
                <div className='service-cont'>
                    <div>
                        <img alt='furnace' className='service-img' src={furnace}/>
                    </div>
                    <div>
                        <h1 className='service-title'>Professional Heating Services</h1>
                        <p className='service-text'>You’ll be sure to know when your system needs repairing because your building will give you subtle signals. Warning signs of furnace trouble include unusual noises, a yellow pilot light, a higher than normal heating bill, and more. The most obvious sign is when you arrive or wake up to a cold room that you can’t control.</p>
                        <p className='service-text'>While the issue you are having might be a simple fix, such as replacing a battery in your thermostat, we highly recommend having an expert take a look at your system to make sure everything is in good working order. That’s where TLC comes in. Our professionals provide homeowners with furnace repairs in the Calgary area with everything from heating repairs to furnace cleaning. With a wide inventory of parts in our trucks, we can repair just about any furnace problems you have during the initial service call, with your approval.</p>
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default Heating;
