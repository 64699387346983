import React from 'react';
import Stars from 'react-star-rating-component';
import '../css/testimonial.css';

function Testimonial(props) {
  return (
    <div className='review'>
        <h4 className='reviewer-name'>{props.name}</h4>
        <Stars
            name='rating'
            starCount={5}
            value={props.rating}
        />
        <p>{props.reviewText}</p>
    </div>
  );
}

export default Testimonial;
