import React,{useState} from 'react';
import logo from '../imgs/TLC logo web-optimized.png';
import '../css/nav.css';
import {HashLink as Link} from 'react-router-hash-link';


function Nav() {
    const [hamStatus, setHam] = useState('false');
    const [servicesOpen, setServicesOpen] = useState('false')
    const collapseMenu = (isDropDown) => {
        if (isDropDown) {
            setServicesOpen(!servicesOpen);
            setHam(!hamStatus); 
        } else {
            setHam(!hamStatus); 
        }
        
    }
    const servicesMobileDropdown = () => {
        return (
            <div className='mobile-services'>
                <ul className='mobile-service-links'>
                    <li><Link onClick={() => collapseMenu(true)} className='mobile-links links' to='/services/heating'>Heating</Link></li>
                    <li><Link onClick={() => collapseMenu(true)} className='mobile-links links' to='/services/cooling'>Cooling</Link></li>
                    <li><Link onClick={() => collapseMenu(true)} className='mobile-links links' to='/services/duct-cleaning'>Ventilation &amp; Duct Cleaning</Link></li>
                    <li><Link onClick={() => collapseMenu(true)} className='mobile-links links' to='/services/installs-and-repairs'>Installations &amp; Repairs</Link></li>
                </ul>
            </div>
        );
    }
    const mobileMenu = () => {
        return (
            <div className='mobile-menu-items'>
                <ul className='nav-links'>
                    <Link onClick={() => collapseMenu(false)} className='links' to='/'>
                        <li>Home</li>
                    </Link> 
                    <Link onClick={() => collapseMenu(false)} className='links' to='/#about' smooth>
                        <li>About</li>
                    </Link>
                    <button className='services-toggle links' onClick={() => setServicesOpen(!servicesOpen)}>Services</button>
                    {(servicesOpen) ? null : servicesMobileDropdown()}
                    <Link onClick={() => collapseMenu(false)} className='links' to='/testimonials'>
                        <li>Testimonials</li>
                    </Link>
                    <a  className='links' href="mailto:tlc-heatingandcoolingltd@shaw.ca" target="_top">Contact</a>
                </ul>
            </div>
        );
    }
  return (
    <div className='header'>
        <div className='wrapper'>
            <Link className='logoLink' to='/'>
                <img className='logo' src={logo} alt='logo'/>
            </Link>
            <div className='desktop-nav'>
                <p>Phone: (403) 880-4827</p>
                <ul className='nav-links'>
                    <Link className='links' to='/'>
                        <li>Home</li>
                    </Link>
                    <Link smooth className='links' to='/#about'>
                        <li>About</li>
                    </Link>
                    <div className='services-dd-cont'>
                        <li className='links'>Services</li>
                        <div className='services-dd'>
                            <div className='divider'></div>
                            <ul className='dropdown-links'>
                                <li><Link to='/services/heating'>Heating</Link></li>
                                <li><Link to='/services/cooling'>Cooling</Link></li>
                                <li><Link to='/services/duct-cleaning'>Ventilation &amp; Duct Cleaning</Link></li>
                                <li><Link to='/services/installs-and-repairs'>Installations &amp; Repairs</Link></li>
                            </ul>
                        </div>
                    </div>
                    <Link className='links' to='/testimonials'>
                        <li>Testimonials</li>
                    </Link>
                    <a className='links' href="mailto:tlc-heatingandcoolingltd@shaw.ca" target="_top">Contact</a>
                </ul>
            </div>
            <div className='mobile-nav'>
                <button className='mobile-menu-button' onClick={() => setHam(!hamStatus)}>Menu</button>
                {(hamStatus) ? null : mobileMenu()}
            </div>
        </div>

    </div>
    
  );
}

export default Nav;
